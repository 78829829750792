html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

html {
  min-height: 100%;
}

body {
  line-height: 1;
  width: 100%;
  overflow-x: hidden;
  font-family: sweet-sans-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  height: 100%;
  margin: 0;
  color: #183540;
  position: relative;
  right: 0;
  transition: .3s ease-in-out;
  overflow-x: hidden;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

*, *:before, *:after {
  box-sizing: border-box;
}

section {
  position: relative;
}

b {
  font-weight: 900;
}

.wrapper {
  padding: 0 90px;
}

@media (max-width: 1600px) {
  .wrapper {
    padding: 0 70px;
  }
}

@media (max-width: 1400px) {
  .wrapper {
    padding: 0 50px;
  }
}

@media (max-width: 1200px) {
  .wrapper {
    padding: 0 30px;
  }
}

@media (max-width: 769px) {
  .wrapper {
    padding: 0 15px;
  }
}

.hover-href {
  transition: .3s ease-in-out;
}

.hover-href:hover {
  color: #ea5552;
}

.swiper-pagination-bullet {
  height: 9px;
  border-radius: 0;
  width: 2px;
  transition: .3s ease-in-out;
  margin: 4.5px 0;
  background: #183540;
}

@media (max-width: 769px) {
  .swiper-pagination-bullet {
    height: 2px;
    width: 9px;
    margin: 0 4.5px;
  }
  .swiper-pagination-bullet:first-child {
    margin-left: 0;
  }
}

.swiper-pagination-bullet-active {
  height: 50px;
  background: #ea5552;
}

@media (max-width: 769px) {
  .swiper-pagination-bullet-active {
    width: 50px;
    height: 2px;
  }
}

.swiper-button-next, .swiper-button-prev {
  color: #183540;
  width: 0;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
  margin: 0;
  height: auto;
}

.swiper-button-next:after, .swiper-button-prev:after {
  content: unset;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
  color: #ea5552;
}

.swiper-button-next {
  transform: rotate(-90deg) translate(100%, 50%);
}

@media (max-width: 769px) {
  .swiper-button-next {
    transform: unset;
  }
}

.swiper-button-prev {
  transform: rotate(-90deg) translate(100%, -70%);
}

@media (max-width: 769px) {
  .swiper-button-prev {
    transform: unset;
  }
}

.swiper-navigation-wrapper {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

@media (max-width: 769px) {
  .swiper-navigation-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    transform: unset;
    margin-bottom: 25px;
  }
}

.swiper-navigation-wrapper__pagination {
  margin: 40px 0px;
}

@media (max-width: 769px) {
  .swiper-navigation-wrapper__pagination {
    margin: 0 40px;
  }
}

.swiper-navigation-wrapper.horizontal {
  margin: 0 auto;
}

.swiper-navigation-wrapper.horizontal .swiper-pagination-bullets {
  flex-direction: row;
}

.swiper-navigation-wrapper.horizontal .swiper-pagination-bullet {
  height: 2px;
  width: 9px;
  margin: 0 4.5px;
}

.swiper-navigation-wrapper.horizontal .swiper-pagination-bullet:first-child {
  margin-left: 0;
}

.swiper-navigation-wrapper.horizontal .swiper-pagination-bullet-active {
  width: 50px;
  height: 2px;
}

.swiper-pagination-bullets {
  display: flex;
  flex-direction: column;
  position: relative;
}

@media (max-width: 769px) {
  .swiper-pagination-bullets {
    flex-direction: row;
  }
}

.split-background {
  height: 760px;
  display: flex;
  position: relative;
}

.split-background > div {
  height: 700px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 70%;
  position: absolute;
}

.split-background__left {
  clip-path: polygon(0 0, 100% 0, 43% 100%, 0% 100%);
  left: 0;
  width: 61% !important;
}

.split-background__right {
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
  right: 0;
  top: -55px;
}

.clients-section {
  padding: 0 70px;
}

@media (max-width: 1600px) {
  .clients-section {
    padding: 0 30px;
  }
}

@media (max-width: 1400px) {
  .clients-section {
    padding: 0;
  }
}

.clients-section .section-title {
  width: 1260px;
  margin: 0 auto;
}

@media (max-width: 769px) {
  .clients-section .section-title {
    width: 100%;
  }
}

.clients-section__list {
  margin: 60px 0 100px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 80px 30px;
  grid-template-areas: ". . . . ." ". . . . .";
}

@media (max-width: 1850px) {
  .clients-section__list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: ". . . ." ". . . .";
  }
}

@media (max-width: 1400px) {
  .clients-section__list {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: ". ." ". ." ". ." ". .";
    gap: 50px 30px;
    margin-bottom: 70px;
  }
}

@media (max-width: 1200px) {
  .clients-section__list {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas: ". ." ". ." ". ." ". .";
    gap: 50px 30px;
    margin-bottom: 50px;
  }
}

@media (max-width: 769px) {
  .clients-section__list {
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .clients-section__list > div {
    width: 309px;
  }
}

.clients-section__list a {
  background-color: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  transition: .3s ease-in-out;
}

@media (max-width: 1400px) {
  .clients-section__list a {
    height: 97px;
  }
}

@media (max-width: 769px) {
  .clients-section__list a {
    width: 309px;
  }
}

.clients-section__list a:hover {
  background-color: #ea5552;
  color: #fff;
}

.main-title {
  text-align: center;
  font-size: 12.5vw;
  color: #ea5552;
  position: relative;
  top: -120px;
  z-index: 9;
  letter-spacing: 5px;
  left: -10px;
}

@media (max-width: 1600px) {
  .main-title {
    top: -100px;
  }
}

@media (max-width: 1400px) {
  .main-title {
    top: -90px;
  }
}

@media (max-width: 1200px) {
  .main-title {
    top: -70px;
  }
}

@media (max-width: 769px) {
  .main-title {
    top: 0;
    left: 0;
    font-size: 11vw;
  }
}

.section-title {
  text-transform: uppercase;
  font-weight: 900;
  position: relative;
}

.section-title:after {
  content: '';
  position: absolute;
  left: 45px;
  top: 7px;
  width: 325px;
  height: 20px;
  background: #ea5552;
  opacity: 0.2;
}

@media (max-width: 769px) {
  .section-title:after {
    width: calc(100% - 40px);
    height: 100%;
  }
}

#black-wall {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .0;
  transition: .3s ease-in-out;
  display: none;
  z-index: -100;
}

@media (max-width: 769px) {
  #black-wall {
    display: block;
  }
}

#black-wall.is-nav-open {
  opacity: .5;
  z-index: 9999;
  left: -220px;
}

#black-wall.is-support-open {
  opacity: .5;
  z-index: 99;
  display: block;
}

#home-swiper {
  width: 100%;
  height: 740px;
  position: relative;
  margin-top: 95px;
}

@media (max-width: 1600px) {
  #home-swiper {
    margin-top: 75px;
    height: 640px;
  }
}

@media (max-width: 1400px) {
  #home-swiper {
    margin-top: 55px;
    height: 540px;
  }
}

@media (max-width: 1200px) {
  #home-swiper {
    margin-top: 35px;
    height: 440px;
  }
}

@media (max-width: 769px) {
  #home-swiper {
    margin: 25px 0;
    height: 340px;
  }
}

#home-swiper p {
  position: absolute;
  top: 0;
  right: 140px;
  text-align: right;
  width: 535px;
}

@media (max-width: 769px) {
  #home-swiper p {
    width: 100%;
    padding: 15px;
    right: 0;
    left: 0;
    text-align: left;
  }
}

#home-swiper .swiper-slide {
  background-size: calc(100% - 70px);
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 769px) {
  #home-swiper .swiper-slide {
    background-size: cover;
  }
}

#reference-swiper {
  width: 930px;
  height: auto;
  margin-top: 50px;
}

@media (max-width: 1200px) {
  #reference-swiper {
    width: 100%;
  }
}

#reference-swiper h4 {
  color: #ea5552;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 30px;
}

#reference-swiper p {
  margin-bottom: 45px;
  font-size: 18px;
}

#reference-swiper h5 {
  font-weight: bold;
  text-transform: uppercase;
}

#reference-swiper h5, #reference-swiper h6 {
  font-size: 15px;
}

.home-reference__swiper .swiper-navigation-wrapper {
  position: relative;
  top: 0;
  transform: unset;
  width: 930px;
}

@media (max-width: 1200px) {
  .home-reference__swiper .swiper-navigation-wrapper {
    width: 100%;
  }
}

.home-reference__swiper .swiper-navigation-wrapper__pagination {
  margin: 90px 0 0 0;
}

@media (max-width: 1600px) {
  .home-reference__swiper .swiper-navigation-wrapper__pagination {
    margin: 80px 0 0 0;
  }
}

@media (max-width: 1400px) {
  .home-reference__swiper .swiper-navigation-wrapper__pagination {
    margin: 70px 0 0 0;
  }
}

@media (max-width: 1200px) {
  .home-reference__swiper .swiper-navigation-wrapper__pagination {
    margin: 50px 0 0 0;
  }
}

@media (max-width: 769px) {
  .home-reference__swiper .swiper-navigation-wrapper__pagination {
    margin: 25px 0 0 0;
  }
}

#header-contact {
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 13px;
  letter-spacing: -1.3px;
}

#header-contact b {
  font-weight: bold;
  letter-spacing: 0;
}

@media (max-width: 769px) {
  #header-contact {
    height: 25px;
    margin-bottom: 15px;
  }
  #header-contact br {
    display: none;
  }
  #header-contact b {
    margin-left: 5px;
  }
  #header-contact a {
    width: 100%;
    text-align: center;
  }
}

#hamburger {
  display: none;
  width: 27px;
  height: 37px;
  position: relative;
  transform: rotate(0deg);
  transition: .3s ease-in-out;
  cursor: pointer;
  z-index: 9999999;
  position: fixed;
  top: 15px;
  right: 15px;
}

@media (max-width: 769px) {
  #hamburger {
    display: block;
  }
}

#hamburger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #000;
  border-radius: 6px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .3s ease-in-out;
}

#hamburger span:nth-child(1) {
  top: 0px;
}

#hamburger span:nth-child(2) {
  top: 8px;
}

#hamburger span:nth-child(3) {
  top: 16px;
  width: 70%;
  margin-left: 30%;
}

#hamburger.is-open {
  display: block;
}

#hamburger.is-open span:nth-child(1) {
  top: 10px;
  transform: rotate(135deg);
}

#hamburger.is-open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#hamburger.is-open span:nth-child(3) {
  top: 10px;
  transform: rotate(-135deg);
  width: 100%;
  margin-left: 0%;
}

@media (max-width: 769px) {
  #mainNav {
    position: fixed;
    right: -340px;
    transition: .3s ease-in-out;
    height: 100%;
    width: 220px;
    top: 0;
    padding-top: 50px;
    background-color: #fff;
    z-index: 999999;
  }
}

#mainNav.is-nav-open {
  right: 0;
}

#mainNav ul {
  display: flex;
}

@media (max-width: 769px) {
  #mainNav ul {
    flex-direction: column;
    align-items: center;
  }
}

#mainNav ul li {
  display: flex;
  align-items: center;
  margin-right: 45px;
  height: 57px;
}

@media (max-width: 769px) {
  #mainNav ul li {
    width: 100%;
    height: auto;
    margin: 10px 0 20px 0;
    justify-content: center;
  }
}

#mainNav ul li a {
  width: 100%;
  font-size: 15px;
}

@media (max-width: 769px) {
  #mainNav ul li a {
    text-align: center;
  }
}

#mainNav ul li a.active {
  color: #ea5552;
}

.home-icon {
  width: 15px;
}

@media (max-width: 769px) {
  .home-icon {
    width: 40px !important;
  }
}

.home-icon.active path {
  fill: #ea5552;
}

.nav-border {
  position: absolute;
  height: 1px;
  width: 0;
  background-color: #ea5552;
  transition: .3s ease-in-out;
}

.main-logo {
  width: 355px;
  margin-right: 95px;
}

@media (max-width: 1600px) {
  .main-logo {
    margin-right: 75px;
  }
}

@media (max-width: 1400px) {
  .main-logo {
    width: 305px;
    margin-right: 55px;
  }
}

@media (max-width: 1200px) {
  .main-logo {
    margin-right: 35px;
    width: 275px;
  }
}

@media (max-width: 769px) {
  .main-logo {
    width: 255px;
    margin-right: 0;
  }
}

.main-nav-wrapper {
  display: flex;
  align-items: center;
}

@media (max-width: 769px) {
  .main-nav-wrapper {
    flex-direction: column;
  }
}

.footer-wrapper {
  border-top: 1px solid #ea5552;
}

.footer-wrapper__top {
  margin: 60px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 769px) {
  .footer-wrapper__top {
    flex-direction: column;
    margin: 20px;
  }
}

.footer-wrapper__top p {
  text-transform: uppercase;
  font-size: 13px;
}

@media (max-width: 769px) {
  .footer-wrapper__top p {
    text-align: center;
  }
}

.footer-logo {
  width: 200px;
}

@media (max-width: 769px) {
  .footer-logo {
    margin-bottom: 15px;
  }
}

.footer-wrapper__bottom {
  display: flex;
  justify-content: space-between;
  padding: 0 0 70px 35px;
  font-size: 13px;
}

@media (max-width: 1600px) {
  .footer-wrapper__bottom {
    padding: 0 0 60px 30px;
  }
}

@media (max-width: 1400px) {
  .footer-wrapper__bottom {
    padding: 0 0 50px 25px;
  }
}

@media (max-width: 1200px) {
  .footer-wrapper__bottom {
    padding: 0 0 40px 20px;
  }
}

@media (max-width: 769px) {
  .footer-wrapper__bottom {
    padding: 0 0 15px 0;
    flex-direction: column;
  }
}

.footer-wrapper__bottom a, .footer-wrapper__bottom span {
  display: block;
  margin-top: 5px;
}

@media (max-width: 769px) {
  .footer-wrapper__bottom a, .footer-wrapper__bottom span {
    text-align: center;
  }
}

.footer-wrapper__bottom > div {
  display: flex;
  align-items: flex-end;
}

@media (max-width: 769px) {
  .footer-wrapper__bottom > div {
    flex-direction: column;
    align-items: center;
  }
}

.footer-wrapper__bottom > div > div {
  margin-left: 30px;
}

@media (max-width: 769px) {
  .footer-wrapper__bottom > div > div {
    margin: 0 0 15px 0;
  }
}

#st18 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 12px;
}

@media (max-width: 769px) {
  #st18 {
    align-items: center;
  }
}

#st18 a {
  display: block;
  margin-top: 5px;
  width: 40px;
}

.home-reference__swiper {
  margin: 0 30px;
}

@media (max-width: 1200px) {
  .home-reference__swiper {
    margin: 0;
    width: 100%;
  }
}

.home-reference {
  margin: 90px auto 245px auto;
  width: 1140px;
}

@media (max-width: 1600px) {
  .home-reference {
    margin: 70px auto 205px auto;
  }
}

@media (max-width: 1400px) {
  .home-reference {
    margin: 50px auto 165px auto;
  }
}

@media (max-width: 1200px) {
  .home-reference {
    margin: 30px auto 100px auto;
    width: 100%;
  }
}

@media (max-width: 769px) {
  .home-reference {
    margin: 30px auto 50px auto;
  }
}

.home-reference > div {
  display: flex;
}

.home-reference > div .quotation {
  display: flex;
  align-items: flex-end;
  margin: 70px 0 45px 0;
}

@media (max-width: 1200px) {
  .home-reference > div .quotation {
    display: none;
  }
}

.home-reference > div .quotation.top {
  align-items: flex-start;
}

.home-reference > div .quotation svg {
  width: 100%;
}

.quotation {
  width: 75px;
}

.home-split-section__icon {
  width: 290px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

@media (max-width: 1200px) {
  .home-split-section__icon {
    transform: translate(-50%, -30%);
  }
}

.home-split-section__content {
  font-size: 60px;
  text-transform: uppercase;
  position: absolute;
  top: 180px;
  left: 90px;
}

@media (max-width: 1600px) {
  .home-split-section__content {
    font-size: 50px;
    left: 70px;
  }
}

@media (max-width: 1400px) {
  .home-split-section__content {
    left: 50px;
    font-size: 40px;
  }
}

@media (max-width: 1200px) {
  .home-split-section__content {
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 769px) {
  .home-split-section__content {
    width: 100%;
  }
}

.home-split-section__content h4 {
  display: flex;
  align-items: center;
}

@media (max-width: 769px) {
  .home-split-section__content h4 {
    justify-content: center;
  }
}

.home-split-section__content b {
  font-weight: 800;
  width: 100px;
  display: block;
}

@media (max-width: 1400px) {
  .home-split-section__content b {
    width: 60px;
  }
}

.home-split-section__content b.margin {
  margin-right: 25px;
}

.home-split-section__content p {
  text-transform: none;
}

.home-split-section {
  height: 470px;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 100px;
}

@media (max-width: 1200px) {
  .home-split-section {
    flex-direction: column;
    height: 370px;
    margin-bottom: 60px;
  }
}

@media (max-width: 769px) {
  .home-split-section {
    margin-bottom: 30px;
  }
}

.home-split-section .background {
  height: 100%;
}

.home-split-section .background:first-child {
  width: 62%;
  background: white;
  background: linear-gradient(180deg, white 58%, rgba(234, 85, 82, 0.140494) 100%);
}

@media (max-width: 1200px) {
  .home-split-section .background:first-child {
    width: 100%;
  }
}

.home-split-section .background:last-child {
  width: 38%;
  background: #ea5552;
  position: relative;
}

@media (max-width: 1200px) {
  .home-split-section .background:last-child {
    display: none;
  }
}

.home-split-section .background:last-child:after {
  content: '';
  position: absolute;
  left: -450px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 470px 450px;
  border-color: transparent transparent #ea5552 transparent;
}

.home-split-section.about-page .background:first-child {
  width: 84%;
}

.home-split-section.about-page .background:last-child {
  width: 16%;
}

.split-background.contact-page {
  margin-bottom: 220px;
}

@media (max-width: 1600px) {
  .split-background.contact-page {
    margin-bottom: 180px;
  }
}

@media (max-width: 1400px) {
  .split-background.contact-page {
    margin-bottom: 140px;
  }
}

@media (max-width: 1200px) {
  .split-background.contact-page {
    margin-bottom: 80px;
  }
}

@media (max-width: 769px) {
  .split-background.contact-page {
    margin-bottom: 50px;
  }
}

.contact-page-info__top {
  padding: 95px 0 110px 0;
}

@media (max-width: 1600px) {
  .contact-page-info__top {
    padding: 75px 0 90px 0;
  }
}

@media (max-width: 1400px) {
  .contact-page-info__top {
    padding: 55px 0 70px 0;
  }
}

@media (max-width: 1200px) {
  .contact-page-info__top {
    padding: 35px 0 50px 0;
  }
}

@media (max-width: 769px) {
  .contact-page-info__top {
    padding: 0 0 30px 0;
  }
}

.contact-page-info__top a {
  display: block;
  font-size: 60px;
  font-weight: 700;
}

@media (max-width: 1400px) {
  .contact-page-info__top a {
    font-size: 50px;
  }
}

@media (max-width: 1400px) {
  .contact-page-info__top a {
    font-size: 30px;
  }
}

@media (max-width: 500px) {
  .contact-page-info__top a {
    font-size: 20px;
  }
}

.contact-page-info__address {
  display: flex;
  font-size: 15px;
}

@media (max-width: 769px) {
  .contact-page-info__address {
    flex-direction: column;
  }
}

.contact-page-info__address span {
  display: block;
}

.contact-page-info__address p {
  width: 450px;
  text-align: right;
  margin-left: 615px;
}

@media (max-width: 1400px) {
  .contact-page-info__address p {
    margin-left: 415px;
  }
}

@media (max-width: 1200px) {
  .contact-page-info__address p {
    margin-left: 100px;
  }
}

@media (max-width: 769px) {
  .contact-page-info__address p {
    margin-left: 0;
    margin-top: 30px;
    width: 100%;
    text-align: left;
  }
}

.contact-page-info {
  margin: 95px 0 220px 0;
  padding: 0 70px;
}

@media (max-width: 1600px) {
  .contact-page-info {
    margin: 75px 0 180px 0;
    padding: 0 50px;
  }
}

@media (max-width: 1400px) {
  .contact-page-info {
    margin: 55px 0 140px 0;
    padding: 0 30px;
  }
}

@media (max-width: 1200px) {
  .contact-page-info {
    margin: 35px 0 80px 0;
  }
}

@media (max-width: 769px) {
  .contact-page-info {
    margin: 25px 0 50px 0;
    padding: 0;
  }
}

.main-title.contact-page {
  top: 60px;
}

.main-title.capabilities-page {
  text-align: left;
  padding: 0 110px;
  top: -180px;
}

@media (max-width: 1600px) {
  .main-title.capabilities-page {
    padding: 0 90px;
  }
}

@media (max-width: 1400px) {
  .main-title.capabilities-page {
    padding: 0 70px;
  }
}

@media (max-width: 1200px) {
  .main-title.capabilities-page {
    padding: 0 50px;
    top: -90px;
  }
}

@media (max-width: 769px) {
  .main-title.capabilities-page {
    padding: 0;
  }
}

.desc-capabilities {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  width: 465px;
  margin: 0 0 155px 230px;
}

@media (max-width: 1600px) {
  .desc-capabilities {
    margin: 0 0 115px 180px;
  }
}

@media (max-width: 1400px) {
  .desc-capabilities {
    margin: 0 0 95px 130px;
  }
}

@media (max-width: 1200px) {
  .desc-capabilities {
    margin: 0 0 65px 80px;
  }
}

@media (max-width: 769px) {
  .desc-capabilities {
    margin: 0 0 30px 0;
    width: 100%;
  }
}

.table-capabilities {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #a3aeb3;
  margin-bottom: 190px;
}

@media (max-width: 1600px) {
  .table-capabilities {
    margin-bottom: 140px;
  }
}

@media (max-width: 1400px) {
  .table-capabilities {
    margin-bottom: 90px;
  }
}

@media (max-width: 1200px) {
  .table-capabilities {
    margin-bottom: 50px;
    flex-direction: column;
  }
}

@media (max-width: 769px) {
  .table-capabilities {
    margin-bottom: 30px;
  }
}

.table-capabilities > div {
  width: calc(50% - 1px);
}

@media (max-width: 1200px) {
  .table-capabilities > div {
    width: 100%;
  }
  .table-capabilities > div:first-child {
    margin-bottom: 20px;
  }
}

.table-capabilities__header {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ea5552;
  font-size: 18px;
  font-weight: 900;
  background: rgba(234, 85, 82, 0.2);
  text-transform: uppercase;
}

.table-capabilities ul li {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  padding: 0 15px;
  text-align: center;
}

.table-capabilities ul li:nth-child(2n+2) {
  background: #f4f4f4;
}

.split-background.capabilities-page {
  height: 620px;
  margin: 95px 70px 0 70px;
}

@media (max-width: 1600px) {
  .split-background.capabilities-page {
    margin: 75px 0 50px 0;
  }
}

@media (max-width: 1400px) {
  .split-background.capabilities-page {
    margin: 55px 0 30px 0;
  }
}

@media (max-width: 1200px) {
  .split-background.capabilities-page {
    margin: 35px 0 15px 0;
  }
}

@media (max-width: 769px) {
  .split-background.capabilities-page {
    margin: 25px 0 0 0;
    height: 420px;
  }
}

.split-background.capabilities-page .split-background__left {
  height: 425px;
  width: 67% !important;
  clip-path: polygon(0 0, 100% 0, 57% 100%, 0% 100%);
}

@media (max-width: 769px) {
  .split-background.capabilities-page .split-background__left {
    height: 325px;
  }
}

.split-background.capabilities-page .split-background__right {
  top: 75px;
  height: 550px;
  width: 74%;
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
}

@media (max-width: 769px) {
  .split-background.capabilities-page .split-background__right {
    height: 450px;
    top: 45px;
    clip-path: polygon(55% 0, 100% 0, 100% 100%, 0% 100%);
  }
}

.main-title.projects-page {
  top: -180px;
}

.desc-projects {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  width: 610px;
  margin: 0 0 90px 230px;
}

@media (max-width: 1600px) {
  .desc-projects {
    margin: 0 0 50px 180px;
  }
}

@media (max-width: 1400px) {
  .desc-projects {
    margin: 0 0 30px 130px;
  }
}

@media (max-width: 1200px) {
  .desc-projects {
    margin: 0 0 30px 80px;
  }
}

@media (max-width: 769px) {
  .desc-projects {
    margin: 0 0 30px 0;
    width: 100%;
  }
}

.desc-projects__under {
  margin-bottom: 70px;
}

.desc-projects__under .section-title {
  margin: 0 0 35px 115px;
}

.desc-projects__under .section-title:after {
  width: 365px;
  height: 100%;
}

@media (max-width: 769px) {
  .desc-projects__under .section-title {
    margin-left: 0;
  }
}

.desc-projects__under a {
  margin-left: 180px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

@media (max-width: 769px) {
  .desc-projects__under a {
    margin-left: 50px;
  }
}

.projects-item {
  height: 570px;
  width: 776px;
  background: #efefef;
  margin-bottom: 30px;
  padding: 55px 35px 25px 35px;
}

@media (max-width: 1880px) {
  .projects-item {
    width: calc(50% - 15px);
  }
}

@media (max-width: 1200px) {
  .projects-item {
    width: 100%;
    height: auto;
  }
}

.projects-item.ended {
  height: 850px;
}

.projects-item.ended .projects-item__desc {
  height: 80px;
}

@media (max-width: 1200px) {
  .projects-item.ended .projects-item__desc {
    height: auto;
  }
}

@media (max-width: 1200px) {
  .projects-item.ended {
    height: auto;
  }
}

.projects-item h2 {
  color: #ea5552;
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
}

.projects-item p {
  font-size: 15px;
  width: 400px;
  font-weight: 700;
  line-height: 1.5;
}

@media (max-width: 1400px) {
  .projects-item p {
    width: 100%;
  }
}

.projects-item__desc {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  height: 190px;
}

@media (max-width: 1400px) {
  .projects-item__desc {
    flex-direction: column;
    justify-content: end;
  }
}

@media (max-width: 1200px) {
  .projects-item__desc {
    height: auto;
  }
}

.projects-item__desc img {
  max-width: 160px;
  width: 100%;
  height: 45px;
}

@media (max-width: 1400px) {
  .projects-item__desc img {
    margin-top: 20px;
  }
}

.projects-item__list {
  margin-top: 55px;
}

.projects-item__list h4 {
  font-size: 15px;
  font-weight: 700;
  color: #ea5552;
}

.projects-item__list ul {
  margin: 10px 0 0 30px;
}

.projects-item__list ul li {
  line-height: 1.5;
}

.ended-photo {
  margin-top: 100px;
  height: 360px;
  width: 100%;
}

.split-background.projects-page {
  height: 800px;
  margin: 95px 0 0 0;
}

@media (max-width: 1600px) {
  .split-background.projects-page {
    margin: 75px 0 0 0;
    height: 700px;
  }
}

@media (max-width: 1400px) {
  .split-background.projects-page {
    margin: 55px 0 0 0;
    height: 600px;
  }
}

@media (max-width: 1200px) {
  .split-background.projects-page {
    margin: 35px 0 0 0;
  }
}

@media (max-width: 769px) {
  .split-background.projects-page {
    margin: 25px 0 0 0;
  }
}

.split-background.projects-page .split-background__left {
  height: 540px;
  width: 63% !important;
  clip-path: polygon(0 0, 100% 0, 61% 100%, 0% 100%);
}

.split-background.projects-page .split-background__right {
  top: 0;
  height: 100%;
  width: 72%;
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
}

@media (max-width: 1600px) {
  .split-background.projects-page .split-background__right {
    width: 67%;
    clip-path: polygon(47% 0, 100% 0, 100% 100%, 0% 100%);
  }
}

@media (max-width: 1400px) {
  .split-background.projects-page .split-background__right {
    width: 62%;
    clip-path: polygon(43% 0, 100% 0, 100% 100%, 0% 100%);
  }
}

.projects-container {
  margin: 0 70px 135px 70px;
}

@media (max-width: 1600px) {
  .projects-container {
    margin: 0 0 105px 0;
  }
}

@media (max-width: 1400px) {
  .projects-container {
    margin: 0 0 75px 0;
  }
}

@media (max-width: 1200px) {
  .projects-container {
    margin: 0 0 50px 0;
  }
}

@media (max-width: 769px) {
  .projects-container {
    margin: 0 0 30px 0;
  }
}

.projects-container:last-child {
  margin-bottom: 115px;
}

.projects-container h3 {
  text-transform: uppercase;
  margin: 0 0 15px 35px;
  font-size: 12px;
  font-weight: 700;
}

.projects-container__wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .projects-container__wrapper {
    flex-direction: column;
  }
}

.main-title.about-page {
  top: -180px;
}

@media (max-width: 769px) {
  .main-title.about-page {
    top: -400px;
  }
}

.desc-about {
  margin: 110px auto;
  font-size: 18px;
  font-weight: 700;
  width: 930px;
}

@media (max-width: 1600px) {
  .desc-about {
    margin-top: 0;
  }
}

@media (max-width: 1200px) {
  .desc-about {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .home-split-section.about-page .background:first-child {
    width: 100%;
  }
  .home-split-section.about-page .home-split-section__content {
    top: 40%;
    width: 100%;
    text-align: center;
  }
}

.our-team {
  padding: 80px 0;
  background: #efefef;
  margin-bottom: 110px;
}

@media (max-width: 1400px) {
  .our-team {
    padding: 50px 15px;
  }
}

.our-team h3 {
  width: 1260px;
  margin: 0 auto 80px auto;
  margin-bottom: 80px;
}

.our-team h3:after {
  width: 85px;
}

@media (max-width: 1400px) {
  .our-team h3 {
    width: 100%;
  }
}

.our-team__list {
  display: flex;
  justify-content: space-between;
  width: 1260px;
  margin: 0 auto;
}

@media (max-width: 1400px) {
  .our-team__list {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .our-team__list {
    flex-direction: column;
    align-items: center;
  }
}

.person-team {
  width: 290px;
}

@media (max-width: 1000px) {
  .person-team {
    margin-bottom: 20px;
  }
}

.person-team__photo {
  width: 290px;
  height: 290px;
}

.person-team h4 {
  margin: 45px 0;
  font-size: 24px;
  font-weight: 900;
  color: #ea5552;
}

.person-team p {
  font-size: 15px;
  font-weight: 700;
  line-height: 1.5;
}

.split-background.about-page {
  margin: 95px 0 0 0;
  height: 800px;
}

@media (max-width: 1600px) {
  .split-background.about-page {
    margin: 75px 0 0 0;
  }
}

@media (max-width: 1400px) {
  .split-background.about-page {
    margin: 55px 0 0 0;
  }
}

@media (max-width: 1200px) {
  .split-background.about-page {
    margin: 35px 0 0 0;
  }
}

@media (max-width: 769px) {
  .split-background.about-page {
    margin: 25px 0 0 0;
  }
}

.split-background.about-page .split-background__left {
  width: 72% !important;
  z-index: 9;
  height: 575px;
  clip-path: polygon(0 0, 100% 0, 60% 100%, 0% 100%);
}

@media (max-width: 1200px) {
  .split-background.about-page .split-background__left {
    height: 505px;
  }
}

.split-background.about-page .split-background__right {
  background: #efefef;
  z-index: 1;
  height: 575px;
  top: 210px;
  clip-path: polygon(40% 0, 100% 0, 100% 100%, 0% 100%);
  width: 75%;
}

@media (max-width: 1200px) {
  .split-background.about-page .split-background__right {
    width: 95%;
  }
}

.split-background.about-page .split-background__right p {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  width: 610px;
  position: absolute;
  right: 65px;
  top: 200px;
}

@media (max-width: 1600px) {
  .split-background.about-page .split-background__right p {
    width: 470px;
  }
}

@media (max-width: 769px) {
  .split-background.about-page .split-background__right p {
    width: 240px;
    top: 340px;
    font-size: 18px;
    right: 0;
  }
}
