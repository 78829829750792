.contact-page-info {
    &__top {
        padding: 95px 0 110px 0;

        @media(max-width: $Xlaptop) {
            padding: 75px 0 90px 0;
        }
        @media(max-width: $laptop) {
            padding: 55px 0 70px 0;
        }
        @media(max-width: $tablet) {
            padding: 35px 0 50px 0;
        }
        @media(max-width: $mobile) {
            padding: 0 0 30px 0;
        }

        a {
            display: block;
            font-size: 60px;
            font-weight: 700;
            @media(max-width: $laptop) {
                font-size: 50px;
            }
            @media(max-width: $laptop) {
                font-size: 30px;
            }
            @media(max-width: 500px) {
                font-size: 20px;
            }
        }
    }

    &__address {
        display: flex;
        font-size: 15px;
        @media(max-width: $mobile) {
            flex-direction: column;
        }

        span {
            display: block;
        }
        
        p {
            width: 450px;
            text-align: right;
            margin-left: 615px;

            @media(max-width: $laptop) {
                margin-left: 415px;
            }
            @media(max-width: $tablet) {
                margin-left: 100px;
            }
            @media(max-width: $mobile) {
                margin-left: 0;
                margin-top: 30px;
                width: 100%;
                text-align: left;
            }
        }
    }
}