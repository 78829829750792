#mainNav {
    @media (max-width: $mobile) { 
        position: fixed;
        right: -340px;
        transition: .3s ease-in-out;
        height: 100%;
        width: 220px;
        top: 0;
        padding-top: 50px;
        background-color: #fff;
        z-index: 999999;
    }
        
    &.is-nav-open {
        right: 0;
    }

    ul {
        display: flex;
        @media (max-width: $mobile) { 
            flex-direction: column;
            align-items: center;
        }

        li {
            display: flex;
            align-items: center;
            margin-right: 45px;
            height: 57px;

            @media (max-width: $mobile) { 
                width: 100%;
                height: auto;
                margin: 10px 0 20px 0;
                justify-content: center;
            }

            a {
                width: 100%;
                font-size: 15px;
                @media (max-width: $mobile) { 
                    text-align: center;
                }

                &.active {
                    color: $main-color;
                }
            }
        }
    }
}

.home-icon {
    width: 15px;    

    @media (max-width: $mobile) { 
        width: 40px !important;
    }

    &.active {
        path {
            fill: $main-color;
        }
    }
}

.nav-border {
    position: absolute;
    height: 1px;
    width: 0;
    background-color: $main-color;
    transition: .3s ease-in-out;
}