#hamburger {
    display: none;
    width: 27px;
    height: 37px;
    position: relative;
    transform: rotate(0deg);
    transition: .3s ease-in-out;
    cursor: pointer;
    z-index: 9999999;

    position: fixed;
    top: 15px;
    right: 15px;


    @media(max-width: 769px) {
        display: block;
    }

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #000;
        border-radius: 6px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .3s ease-in-out;

        &:nth-child(1) {
            top: 0px;
        }
        &:nth-child(2) {
            top: 8px;
        }
        &:nth-child(3) {
            top: 16px;
            width: 70%;
            margin-left: 30%;
        }
    }

    &.is-open {
        display: block;
        span {
            &:nth-child(1) {
                top: 10px;
                transform: rotate(135deg);
            }
            &:nth-child(2) {
                opacity: 0;
                left: -60px;
            }
            &:nth-child(3) {
                top: 10px;
                transform: rotate(-135deg);
                width: 100%;
                margin-left: 0%;
            }
        }
    }
}
  