.main-title {
    &.projects-page {
        top: -180px;

        // @media(max-width: $Xlaptop) {
        //     padding: 0 90px;
        // }
        // @media(max-width: $laptop) {
        //     padding: 0 70px;
        // }
        // @media(max-width: $tablet) {
        //     padding: 0 50px;
        //     top: -90px;
        // }
        // @media(max-width: $mobile) {
        //     padding: 0;
        // }
    }
}


.desc-projects {
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    width: 610px;
    margin: 0 0 90px 230px;

    @media(max-width: $Xlaptop) {
        margin: 0 0 50px 180px;
    }
    @media(max-width: $laptop) {
        margin: 0 0 30px 130px;
    }
    @media(max-width: $tablet) {
        margin: 0 0 30px 80px;
    }
    @media(max-width: $mobile) {
        margin: 0 0 30px 0;
        width: 100%;
    }

    &__under {
        margin-bottom: 70px;
        .section-title {
            margin: 0 0 35px 115px;
            &:after {
                width: 365px;  
                height: 100%;
            }

            @media(max-width: $mobile) {
                margin-left: 0;
            }
        }

        a {
            margin-left: 180px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 700;
            @media(max-width: $mobile) {
                margin-left: 50px;
            }
        }
    }
}