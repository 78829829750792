.main-title {
    &.capabilities-page {
        text-align: left;
        padding: 0 110px;
        top: -180px;

        @media(max-width: $Xlaptop) {
            padding: 0 90px;
        }
        @media(max-width: $laptop) {
            padding: 0 70px;
        }
        @media(max-width: $tablet) {
            padding: 0 50px;
            top: -90px;
        }
        @media(max-width: $mobile) {
            padding: 0;
        }
    }
}

.desc-capabilities {
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    width: 465px;
    margin: 0 0 155px 230px;

    @media(max-width: $Xlaptop) {
        margin: 0 0 115px 180px;
    }
    @media(max-width: $laptop) {
        margin: 0 0 95px 130px;
    }
    @media(max-width: $tablet) {
        margin: 0 0 65px 80px;
    }
    @media(max-width: $mobile) {
        margin: 0 0 30px 0;
        width: 100%;
    }
}