.wrapper {
    padding: 0 90px;
    @media(max-width: $Xlaptop) {
        padding: 0 70px;
    }
    @media(max-width: $laptop) {
        padding: 0 50px;
    }
    @media(max-width: $tablet) {
        padding: 0 30px;
    }
    @media(max-width: $mobile) {
        padding: 0 15px;
    }
}