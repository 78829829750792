.projects-item {
    height: 570px;
    width: 776px;
    background: #efefef;
    margin-bottom: 30px;
    padding: 55px 35px 25px 35px;

    @media(max-width: 1880px) {
        width: calc(50% - 15px);
    }
    @media(max-width: $tablet) {
        width: 100%;
        height: auto;
    }

    &.ended {
        height: 850px;
        .projects-item__desc {
            height: 80px;
            @media(max-width: $tablet) {
                height: auto;
            }
        }
        @media(max-width: $tablet) {
            height: auto;
        }
    }

    h2 {
        color: $main-color;
        font-size: 24px;
        font-weight: 900;
        text-transform: uppercase;
    }

     p {
        font-size: 15px;
        width: 400px;
        font-weight: 700;
        line-height: 1.5;
        @media(max-width: $laptop) {
            width: 100%;
        }
    }  

    &__desc {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        height: 190px;

        @media(max-width: $laptop) {
            flex-direction: column;
            justify-content: end;
        }
        @media(max-width: $tablet) {
            height: auto;
        }

        img {
            max-width: 160px;
            width: 100%;
            height: 45px;

            @media(max-width: $laptop) {
                margin-top: 20px;
            }
    
        }
    }

    &__list {
        margin-top: 55px;

        h4 {
            font-size: 15px;
            font-weight: 700;
            color: $main-color;
        }

        ul {
            margin: 10px 0 0 30px;

            li {
                line-height: 1.5;
            }
        }
    }
}

.ended-photo {
    margin-top: 100px;
    height: 360px;
    width: 100%;
}