.split-background  {
    &.about-page {
        margin: 95px 0 0 0;
        height: 800px;
        @media(max-width: $Xlaptop) {
            margin: 75px 0 0 0;
        }
        @media(max-width: $laptop) {
            margin: 55px 0 0 0;
        }
        @media(max-width: $tablet) {
            margin: 35px 0 0 0;
        }
        @media(max-width: $mobile) {
            margin: 25px 0 0 0;
        }

        .split-background__left {
            width: 72% !important;
            z-index: 9;
            height: 575px;
            clip-path: polygon(0 0, 100% 0, 60% 100%, 0% 100%);

            @media(max-width: $tablet) {
                height: 505px;
            }
        }

        .split-background__right {
            background: #efefef;
            z-index: 1;
            height: 575px;
            top: 210px;
            clip-path: polygon(40% 0, 100% 0, 100% 100%, 0% 100%);
            width: 75%;
            @media(max-width: $tablet) {
                width: 95%;
            }
            p {
                font-size: 24px;
                font-weight: 900;
                text-transform: uppercase;
                width: 610px;
                position: absolute;
                right: 65px;
                top: 200px;
                @media(max-width: $Xlaptop) {
                    width: 470px;
                }
                @media(max-width: $mobile) {
                    width: 240px;
                    top: 340px;
                    font-size: 18px;
                    right: 0;
                }
            }
        }
    }
}