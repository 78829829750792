.footer-wrapper__bottom {
    display: flex;
    justify-content: space-between;
    padding: 0 0 70px 35px;
    font-size: 13px;
    @media(max-width: $Xlaptop) {
        padding: 0 0 60px 30px;
    }
    @media(max-width: $laptop) {
        padding: 0 0 50px 25px;
    }
    @media(max-width: $tablet) {
        padding: 0 0 40px 20px;
    }
    @media(max-width: $mobile) {
        padding: 0 0 15px 0;
        flex-direction: column;
    }

    a, span {
        display: block;
        margin-top: 5px;
        @media(max-width: $mobile) {
            text-align: center;
        }
    }

    > div {
        display: flex;
        align-items: flex-end;
        @media(max-width: $mobile) {
            flex-direction: column;
            align-items: center;
        }

        > div {
            margin-left: 30px;
            @media(max-width: $mobile) {
                margin: 0 0 15px 0;
            }
        }
    }
}


#st18 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 12px;
    @media(max-width: $mobile) {
       align-items: center;
    }
    a {
        display: block;
        margin-top: 5px;
        width: 40px;
    }
}
