.split-background {
    &.projects-page {
        height: 800px;
        margin: 95px 0 0 0;

        @media(max-width: $Xlaptop) {
            margin: 75px 0 0 0;
            height: 700px;
        }
        @media(max-width: $laptop) {
            margin: 55px 0 0 0;
            height: 600px;
        }
        @media(max-width: $tablet) {
            margin: 35px 0 0 0;
        }
        @media(max-width: $mobile) {
            margin: 25px 0 0 0;
            // height: 420px;
        }

        .split-background__left {
            height: 540px;
            width: 63% !important;
            clip-path: polygon(0 0, 100% 0, 61% 100%, 0% 100%);
            @media(max-width: $mobile) {
                // height: 325px;
            }
        }

        .split-background__right {
            top: 0;
            height: 100%;
            width: 72%;
            clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
            @media(max-width: $Xlaptop) {
                width: 67%;
                clip-path: polygon(47% 0, 100% 0, 100% 100%, 0% 100%);
            }
            @media(max-width: $laptop) {
                width: 62%;
                clip-path: polygon(43% 0, 100% 0, 100% 100%, 0% 100%);
            }
            @media(max-width: $mobile) {
                // top: 0;
                // clip-path: polygon(55% 0, 100% 0, 100% 100%, 0% 100%);
            }
        }
    }
}

// container projects

.projects-container {
    margin: 0 70px 135px 70px;

    @media(max-width: $Xlaptop) {
        margin: 0 0 105px 0;
    }
    @media(max-width: $laptop) {
        margin: 0 0 75px 0;
    }
    @media(max-width: $tablet) {
        margin: 0 0 50px 0;
    }
    @media(max-width: $mobile) {
        margin: 0 0 30px 0;
    }

    &:last-child {
        margin-bottom: 115px;
    }

    h3 {
        text-transform: uppercase;
        margin: 0 0 15px 35px;
        font-size: 12px;
        font-weight: 700;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media(max-width: $tablet) {
            flex-direction: column;
        }
    }
}