.swiper-pagination-bullet {
    height: 9px;
    border-radius: 0;
    width: 2px;
    transition: .3s ease-in-out;
    margin: 4.5px 0;
    background: $font-color;

    @media(max-width: $mobile) {
        height: 2px;
        width: 9px;
        margin: 0 4.5px;
        
        &:first-child {
            margin-left: 0;
        }
    }
}

.swiper-pagination-bullet-active {
    height: 50px;
    background: $main-color;
    @media(max-width: $mobile) {
        width: 50px;
        height: 2px;
    }
}

.swiper-button-next, .swiper-button-prev {
    
    color: $font-color;
    width: 0;
    outline: none;
    transition: .3s ease-in-out;
    position: relative;
    margin: 0;
    height: auto;
    
    &:after {
        content: unset;
    }

    &:hover {
        color: $main-color;
    }
}

.swiper-button-next {
    transform: rotate(-90deg) translate(100%, 50%);
    @media(max-width: $mobile) {
        transform: unset;
    }
}

.swiper-button-prev {
    transform: rotate(-90deg) translate(100%, -70%);
    @media(max-width: $mobile) {
        transform: unset;
    }
}


.swiper-navigation-wrapper {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    @media(max-width: $mobile) {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;
        top: 0;
        transform: unset;
        margin-bottom: 25px;
    }

    &__pagination {
        margin: 40px 0px;
        @media(max-width: $mobile) {
            margin: 0 40px;
        }
    }

    &.horizontal {
        margin: 0 auto;
        .swiper-pagination-bullets {
            flex-direction: row;
        }

        .swiper-pagination-bullet {
            height: 2px;
            width: 9px;
            margin: 0 4.5px;

            &:first-child {
                margin-left: 0;
            }
        }

        .swiper-pagination-bullet-active {
            width: 50px;
            height: 2px;
        }

    }
}

.swiper-pagination-bullets {
    display: flex;
    flex-direction: column;
    position: relative;

    @media(max-width: $mobile) {
        flex-direction: row;
    }
}