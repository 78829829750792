.footer-wrapper__top {
    margin: 60px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width: $mobile) {
        flex-direction: column;
        margin: 20px;
    }

    p {
        text-transform: uppercase;
        font-size: 13px;
        @media(max-width: $mobile) {
            text-align: center;
        }
    }
}

.footer-logo {
    width: 200px;
    @media(max-width: $mobile) {
        margin-bottom: 15px;
    }
}