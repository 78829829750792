.table-capabilities {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid $grey;
    margin-bottom: 190px;
    @media(max-width: $Xlaptop) {
        margin-bottom: 140px;
    }
    @media(max-width: $laptop) {
        margin-bottom: 90px;
    }
    @media(max-width: $tablet) {
        margin-bottom: 50px;
        flex-direction: column;
    }
    @media(max-width: $mobile) {
        margin-bottom: 30px;
    }

    > div {
        width: calc(50% - 1px);
        @media(max-width: $tablet) {
            width: 100%;
            &:first-child {
                margin-bottom: 20px;
            }
        }
    }

    &__header {
        height: 65px;
        display: flex;
        align-items: center; 
        justify-content: center;
        color: $main-color;
        font-size: 18px;
        font-weight: 900;
        background: rgba(234, 85, 82, 0.2);
        text-transform: uppercase;
    }

    ul {
        li {
            height: 60px;
            display: flex;
            align-items: center; 
            justify-content: center;
            font-size: 15px;
            padding: 0 15px;
            text-align: center;

            &:nth-child(2n+2) {
                background: #f4f4f4;
            }
        }
    }
}