.home-reference {
    margin: 90px auto 245px auto;
    width: 1140px;
    // padding: 0 270px;
    @media (max-width: $Xlaptop) { 
        margin: 70px auto 205px auto;
    }
    @media (max-width: $laptop) { 
        margin: 50px auto 165px auto;
    }
    @media (max-width: $tablet) { 
        margin: 30px auto 100px auto;
        width: 100%;
    }
    @media (max-width: $mobile) { 
        margin: 30px auto 50px auto;
    }

    > div {
        display: flex;
        .quotation {
            display: flex;
            align-items: flex-end;
            margin: 70px 0 45px 0;
            @media (max-width: $tablet) { 
                display: none;
            }
            &.top {
                align-items: flex-start;
            }

            svg {
                width: 100%;
            }
        }
    }
}

.quotation {
    width: 75px;
}