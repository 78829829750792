.split-background  {
    &.contact-page {
        margin-bottom: 220px;
        @media(max-width: $Xlaptop) {
            margin-bottom: 180px;
        }
        @media(max-width: $laptop) {
            margin-bottom: 140px;
        }
        @media(max-width: $tablet) {
            margin-bottom: 80px;
        }
        @media(max-width: $mobile) {
            margin-bottom: 50px;
        }
    }
}