.section-title {
    text-transform: uppercase;
    font-weight: 900;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        left: 45px;
        top: 7px;
        width: 325px;
        height: 20px;
        background: $main-color;
        // z-index: -1;
        opacity: 0.2;

        @media(max-width: $mobile) {
            width: calc(100% - 40px);
            height: 100%;        
        }
    }
}