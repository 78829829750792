.main-logo {
    width: 355px;
    margin-right: 95px;
    @media(max-width: $Xlaptop) {
        margin-right: 75px;
    }
    @media(max-width: $laptop) {
        width: 305px;
        margin-right: 55px;
    }
    @media(max-width: $tablet) {
        margin-right: 35px;
        width: 275px;
    }
    @media(max-width: $mobile) {
        width: 255px;
        margin-right: 0;
    }
}