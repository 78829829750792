.home-split-section {
    height: 470px;
    width: 100%;
    display: flex;
    position: relative;
    margin-bottom: 100px;

    @media(max-width: $tablet) {
        flex-direction: column;
        height: 370px;
        margin-bottom: 60px;
    }
    
    @media(max-width: $mobile) {
        margin-bottom: 30px;
    }

    .background {
        height: 100%;

        &:first-child {
            width: 62%;
            background: rgb(255,255,255);
            background: linear-gradient(180deg, rgba(255,255,255,1) 58%, rgba(234,85,82,0.14049369747899154) 100%);
            @media(max-width: $tablet) {
                width: 100%;
             }
        }

        &:last-child {
            width: 38%;
            background: $main-color;
            position: relative;
            @media(max-width: $tablet) {
                display: none;
            }

            &:after {
                content: '';
                position: absolute;
                left: -450px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 470px 450px;
                border-color: transparent transparent $main-color transparent;
            }
        }
    }

    
    &.about-page {
        .background {
    
            &:first-child {
                width: 84%;
            }
    
            &:last-child {
                width: 16%;
            }
        }
    }
}