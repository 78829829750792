.home-split-section {
    &__icon {
        width: 290px;
        position: absolute;

        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%);
        z-index: 9;

        @media(max-width: $tablet) {
            transform: translate(-50%, -30%);
        }
    }

    &__content {
        font-size: 60px;
        text-transform: uppercase;
        position: absolute;
        top: 180px;
        left: 90px;

        @media (max-width: $Xlaptop) { 
            font-size: 50px;
            left: 70px;
        }
        @media(max-width: $laptop) {
            left: 50px;
            font-size: 40px;
        }
        @media(max-width: $tablet) {
            top: 10%; 
            left: 50%; 
            transform: translate(-50%, -50%);
        }
        @media(max-width: $mobile) {
            width: 100%;
        }

        h4 {
            display: flex;
            align-items: center;
            @media(max-width: $mobile) {
                justify-content: center;
            }
    
        }

        b {
            font-weight: 800;
            width: 100px;
            display: block;
            @media(max-width: $laptop) {
                width: 60px;
            }

            &.margin {
                margin-right: 25px;
            }
        }

        p {
            text-transform: none;
        }
    }
}