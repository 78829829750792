.split-background {
    height: 760px;
    display: flex;
    position: relative;

    > div {
        height: 700px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 70%;
        position: absolute;
    }

    &__left {
        clip-path: polygon(0 0, 100% 0, 43% 100%, 0% 100%);
        left: 0;
        width: 61% !important;
    }

    &__right {
        clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
        right: 0;
        top: -55px;
    }
}