#black-wall {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .0;
    transition: .3s ease-in-out;
    display: none;
    z-index: -100;

    @media (max-width: 769px) {
        display: block;
    }

    &.is-nav-open {
        opacity: .5;
        z-index: 9999;
        left: -220px;
    }

    &.is-support-open {
        opacity: .5;
        z-index: 99;
        display: block;
    }
}
