#header-contact {
    height: 50px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 13px;
    letter-spacing: -1.3px;

    b {
        font-weight: bold;
        letter-spacing: 0;
    }

    @media(max-width: $mobile) {
        height: 25px;
        margin-bottom: 15px;
        br {
            display: none;
        }

        b {
            margin-left: 5px;
        }
        
        a {
            width: 100%;
            text-align: center;
        }
    }
}