.main-title {
    &.about-page {
        top: -180px;
        @media(max-width: $mobile) {
            top: -400px;
        }
    }
}

.desc-about {
    margin: 110px auto;
    font-size: 18px;
    font-weight: 700;

    width: 930px;

    @media(max-width: $Xlaptop) {
        margin-top: 0;
    }
    @media(max-width: $tablet) {
        width: 100%;
    }
}