.main-title {
    // font-size: 240px;
    text-align: center;
    font-size: 12.5vw;
    color: $main-color;
    position: relative;
    top: -120px;
    z-index: 9;
    letter-spacing: 5px;
    left: -10px;
    @media (max-width: $Xlaptop) { 
        top: -100px;
    }
    @media (max-width: $laptop) { 
        top: -90px;
    }
    @media (max-width: $tablet) { 
        top: -70px;
    }
    @media (max-width: $mobile) { 
        top: 0;
        left: 0;
        font-size: 11vw;
    }
}