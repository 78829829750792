#reference-swiper {
    width: 930px;
    height: auto;
    margin-top: 50px;
    @media (max-width: $tablet) { 
        width: 100%;
    }
    h4 {
        color: $main-color;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 900;
        margin-bottom: 30px;
    }

    p {
        margin-bottom: 45px;
        font-size: 18px;
    }

    h5 {
        font-weight: bold;
        text-transform: uppercase;
    }

    h5,h6 {
        font-size: 15px;
    }
}

.home-reference__swiper {
    .swiper-navigation-wrapper {
        position: relative;
        top: 0;
        transform: unset;
        width: 930px;
        @media (max-width: $tablet) { 
            width: 100%;
        }
        
        &__pagination {
            margin: 90px 0 0 0;
            @media (max-width: $Xlaptop) { 
                margin: 80px 0 0 0;
            }
            @media (max-width: $laptop) { 
                margin: 70px 0 0 0;
            }
            @media (max-width: $tablet) { 
                margin: 50px 0 0 0;
            }
            @media (max-width: $mobile) { 
                margin: 25px 0 0 0;
            }
        }
    }
}