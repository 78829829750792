.split-background {
    &.capabilities-page {
        height: 620px;
        margin: 95px 70px 0 70px;

        @media(max-width: $Xlaptop) {
            margin: 75px 0 50px 0;
        }
        @media(max-width: $laptop) {
            margin: 55px 0 30px 0;
        }
        @media(max-width: $tablet) {
            margin: 35px 0 15px 0;
        }
        @media(max-width: $mobile) {
            margin: 25px 0 0 0;
            height: 420px;
        }

        .split-background__left {
            height: 425px;
            width: 67% !important;
            clip-path: polygon(0 0, 100% 0, 57% 100%, 0% 100%);
            @media(max-width: $mobile) {
                height: 325px;
            }
        }

        .split-background__right {
            top: 75px;
            height: 550px;
            width: 74%;
            clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
            @media(max-width: $mobile) {
                height: 450px;
                top: 45px;
                clip-path: polygon(55% 0, 100% 0, 100% 100%, 0% 100%);
            }
        }
    }
}