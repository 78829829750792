.home-split-section {
    &.about-page {
        @media(max-width: $tablet) {
            .background {
                &:first-child {
                    width: 100%;
                }
            }

            .home-split-section__content {
                top: 40%;
                width: 100%;
                text-align: center;
            }
        }
    }
}