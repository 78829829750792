.contact-page-info {
    margin: 95px 0 220px 0;
    padding: 0 70px;
    @media(max-width: $Xlaptop) {
         margin: 75px 0 180px 0;
         padding: 0 50px;
    }
    @media(max-width: $laptop) {
         margin: 55px 0 140px 0;
         padding: 0 30px;
    }
    @media(max-width: $tablet) {
         margin: 35px 0 80px 0;
    }
    @media(max-width: $mobile) {
         margin: 25px 0 50px 0;
         padding: 0;
    }
}

.main-title {
    &.contact-page {
        top: 60px;
    }
}