.our-team {
    padding: 80px 0;
    background: #efefef;
    margin-bottom: 110px;
    @media(max-width: $laptop) {
        padding: 50px 15px;
    }
    h3 {
        width: 1260px;
        margin: 0 auto 80px auto;
        margin-bottom: 80px;

        &:after {
            width: 85px;
        }

        @media(max-width: $laptop) {
           width: 100%;
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
        width: 1260px;
        margin: 0 auto;
        @media(max-width: $laptop) {
            width: 100%;
        }
        @media(max-width: 1000px) {
            flex-direction: column;
            align-items: center;
        }
    }
}

.person-team {
    width: 290px;
    @media(max-width: 1000px) {
        margin-bottom: 20px;
    }

    &__photo {
        width: 290px;
        height: 290px;
    }

    h4 {
        margin: 45px 0;
        font-size: 24px;
        font-weight: 900;
        color: $main-color;
    }

    p {
        font-size: 15px;
        font-weight: 700;
        line-height: 1.5;
    }
}