#home-swiper {
    width: 100%;
    height: 740px;
    position: relative;
    margin-top: 95px;
    @media(max-width: $Xlaptop) {
        margin-top: 75px;
        height: 640px;
    }
    @media(max-width: $laptop) {
        margin-top: 55px;
        height: 540px;
    }
    @media(max-width: $tablet) {
        margin-top: 35px;
        height: 440px;
    }
    @media(max-width: $mobile) {
        margin: 25px 0;
        height: 340px;
    }

    p {
        position: absolute;
        top: 0;
        right: 140px;
        text-align: right;
        width: 535px;
        @media(max-width: $mobile) {
            width: 100%;
            padding: 15px;
            right: 0;
            left: 0;
            text-align: left;
        }
    }

    .swiper-slide {
        background-size: calc(100% - 70px);;
        background-repeat: no-repeat;
        background-position: center;
        @media(max-width: $mobile) {
            background-size: cover;
        }
    }
}