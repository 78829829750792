.clients-section {
    padding: 0 70px;

    @media(max-width: $Xlaptop) {
        padding: 0 30px;
    }

    @media(max-width: $laptop) {
        padding: 0;
    }

    .section-title {
        width: 1260px;
        margin: 0 auto;
        @media(max-width: $mobile) {
            width: 100%;
        }
    }

    &__list {
        margin: 60px 0 100px 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 80px 30px;
        grid-template-areas:
            ". . . . ."
            ". . . . .";

        @media(max-width: 1850px) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-areas: ". . . ." ". . . .";
        }

        @media(max-width: $laptop) {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            grid-template-areas: ". ." ". ." ". ." ". .";
            gap: 50px 30px;
            margin-bottom: 70px;
        }
    
        @media(max-width: $tablet) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;
            grid-template-areas: ". ." ". ." ". ." ". .";
            gap: 50px 30px;
            margin-bottom: 50px;
        }
    
        @media(max-width: $mobile) {
            margin-bottom: 30px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            >div {
                width: 309px;
            }
        }

        a {
            background-color: #efefef;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 700;
            transition: .3s ease-in-out;

            @media(max-width: $laptop) {
                height: 97px;
            }
        
            @media(max-width: $mobile) {
                width: 309px;
            }

            &:hover {
                background-color: $main-color;
                color: #fff;
            }
        }
    }
}